/*global Component */
class homeItemCardComponent extends Component {

    static name() {
        return "homeItemCardComponent";
    }

    static componentName() {
        return "homeItemCardComponent";
    }

    getProps() {
        return  {
            section:{
                type: Object,
                required: true
            },
            items: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            titleCenter: {
                type: Boolean,
                default: false
            },
            hideTitle: {
                type: Boolean,
                default: false
            },
            fullScreen: {
                type: Boolean,
                default: false
            },
        };
    }

    data() {
        return {
            itemsPerPage: ($(window).width() <= 991) ? 2 : 4,
            initPage:0,
            animate:true,
            interval:8000,
            animateEvent:null
        };
    }

    mounted() {
        return function () {
            this.animateCallback.bind(this);
            window.$homeItemCard = this;
            this.$nextTick(function () {
                if(this.animate)
                    this.animateCallback();
            });

        };
    }

    getComputed() {
        return {
            maxPages:function (){
                if(Array.isArray(this.items))
                    return this.items.length;
                return 0;
            },
            controlActivate:function (){
                return this.maxPages > this.itemsPerPage;
            }
        };
    }

    getMethods() {
        return {
            itemCarouselMoveLeft:this.itemCarouselMoveLeft,
            itemCarouselMoveRight:this.itemCarouselMoveRight,
            animateCallback:this.animateCallback,
            animateStop:this.animateStop
        };
    }

    itemCarouselMoveLeft(){
        if(this.initPage - 1 >= 0)
            this.initPage -= 1;
    }

    itemCarouselMoveRight(){
        if(this.initPage + 1 + this.itemsPerPage > this.maxPages)
            this.initPage = 0;
        else
            this.initPage += 1;

    }

    animateCallback(){
        if(this.controlActivate) {
            this.itemCarouselMoveRight();
            this.animateEvent = setTimeout(this.animateCallback, this.interval);
        }
    }

    animateStop(){
        clearTimeout(this.animateEvent);
    }


    getTemplate() {
        return `<div :key="UIComponentID+'item'+section.internalId+title+'container'" class="container-fluid" >
                    <div ref="homeItemCarousel" class=" row">
                        <div  :class="{'col-md-10':!fullScreen,'offset-md-1':!fullScreen,'col':fullScreen}">
                           <h2 class="col-12 section-title" :class="{'text-center':titleCenter}" v-if="!hideTitle">{{tr(title)}} ({{items.length}})</h2>
                           <div class="item-carousel-inner row justify-content-center"  @mouseover="animateStop" @mouseleave="animateCallback">
                                <template v-for="item of items.slice(initPage,initPage+itemsPerPage)">
                                  <div :key="UIComponentID+'itemcard-'+section.internalId+'-'+item.Code"  class="col-6 col-lg-3">
                                       <div class="produktliste oo-item-container oo-item-container-normal" >
                                            <itemViewModuleComponent :item="item" :showCrossOutPrice="true"></itemViewModuleComponent>
                                        </div>
                                  </div>
                                </template>
                          </div>
                          <div class="row justify-content-center itemcard-control-container" v-if="controlActivate">
                              <a :key="title+'left'" class="itemcard-control itemcard-control-prev" href="javascript:void(0)" :title="tr('Previous')" @click="itemCarouselMoveLeft">
                                          <i class="fa fa-lg fa-chevron-left"></i>
                              </a>
                              <a :key="title+'right'" class="itemcard-control itemcard-control-next" href="javascript:void(0)" :title="tr('Next')" @click="itemCarouselMoveRight">
                                        <i class="fa fa-lg fa-chevron-right"></i>
                              </a>
                          </div>
                        </div>
                    </div>
              </div>`;
    }
}

homeItemCardComponent.registerComponent();